<template>
  <b-row no-gutters class="mt-5 mb-3 mx-3">
    <b-col cols="12">
      <h1>Overenie emailu</h1>
    </b-col>
    <b-col v-if="!token">
      <b-row>
        <b-col>
          <p>Po registrácii je potrebné overiť tvoj zadaný email.</p>
          <p v-if="email">
            <span class="font-weight-bold"> Odkaz na overenie emailu </span>
            sme ti pri registrácii poslali na adresu:
            <span class="font-weight-bold">
              {{ email }}
            </span>
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          Neprišiel ti žiaden email?
          <vue-recaptcha
            ref="verifyRecaptcha"
            :load-recaptcha-script="true"
            :sitekey="recaptchaSitekey"
            class="d-inline-block"
            @verify="resend"
          >
            <b-button
              type="submit"
              variant="link"
              class="py-0"
              :disabled="loading"
            >
              <LoadingSpinner :is-loaded="!loading" small>
                <font-awesome-icon :icon="['fas', 'redo']" />
                Klikni sem pre opätovné zaslanie odkazu na overenie.
              </LoadingSpinner>
            </b-button>
          </vue-recaptcha>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { apiUsers, LoadingSpinner } from "frontend-common";
import constants from "../constants";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "EmailVerification",
  components: {
    LoadingSpinner,
    VueRecaptcha,
  },
  mixins: [apiUsers],
  props: {
    token: {
      type: String,
      default: null,
    },
    email: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      recaptchaSitekey: constants.recaptchaSitekey,
      loading: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    if (this.token) {
      this.verify(this.token);
    }
  },
  methods: {
    resend(recaptcha) {
      this.loading = true;
      return this.apiEmailSendVerification(this.email, recaptcha)
        .then(() => {
          this.$root.successModalOk(
            "Poslali sme ti znova odkaz na overenie emailu.",
            "Môže trvať niekoľko minút kým ti email príde, prípadne skontroluj aj svoj SPAM.",
          );
        })
        .finally(() => {
          this.loading = false;
          this.$refs.verifyRecaptcha.reset();
        });
    },
    verify(token) {
      this.apiEmailVerifyToken(token)
        .then((response) => {
          this.$root.successToast("Email bol úspešne overený!");
          this.$root
            .setAuthToken(response.key)
            .then(() => this.$router.push("/"));
        })
        .catch(() => {
          const h = this.$createElement;
          this.$root
            .dangerModalOk(
              "Chyba pri overovaní emailu",
              h("span", [
                "Môžeš si skúsiť nechať poslať link na overenie emailu znova. ",
                "Pozor, je platný len tri dni. ",
                "V prípade pretrvávajúcich problémov nás prosím kontaktuj na ",
                h("b-link", { domProps: { href: "mailto:it@p-mat.sk" } }, [
                  "it@p-mat.sk",
                ]),
              ]),
            )
            .then(() => this.$router.push({ name: "email-verification" }));
        });
    },
  },
};
</script>

<style scoped></style>
